.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: var(--white);
}
.log {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 12px;
  right: 16px;
}
.arrow {
  transform: rotate(180deg);
}
.menuBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 32px;
  width: auto;
  height: 124px;
  font-size: 12px;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 56px;
  right: 16px;
  box-shadow: 2px 2px 40px 0px rgba(25, 25, 25, 0.1);
  z-index: 2;
}
.logout {
  display: flex;
  gap: 8px;
  padding-top: 24px;
  align-items: center;
  cursor: pointer;
}

.mail {
  color: var(--soft-300);
}

.closed {
  display: none;
}
