.form {
  padding: 40px 101px 32px 101px;
}

.form div:last-child {
  margin-bottom: 0;
}

.formGrid {
  padding: 80px;
  display: grid;
  grid-auto-flow: column;
  grid-template: auto auto / minmax(auto, 400px) minmax(auto, 400px);
  gap: 40px 16px;
}

.formItem {
  width: 400px;
}

.formItemNoMargin {
  composes: formItem;
  margin-bottom: 0;
}

.inputIcon {
  padding: 0px 16px;
}
