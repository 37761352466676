.camerasListLayout {
  display: flex;
  align-items: flex-start;
  max-width: 1280px;
  margin: auto;
  min-height: 698px;
  width: 100%;
  justify-content: center;
  height: 100%;
}
