.cameraList {
  list-style: none;
  display: flex;
  row-gap: 24px;
  padding: 0;
  margin: 0;
}

.cameraCard {
  min-width: 236px;
  min-height: 148px;
  background: var(--light-300);
  border: 1px solid var(--light-400);
  border-radius: 8px; color: var(--soft-700);
  cursor: pointer;
  display: flex;
  flex-flow: column;
  margin: 12px;
}

.cameraCard:hover {
  border: 1px solid var(--gold-500);
  color: var(--gold-500);
}
