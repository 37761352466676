.modalMessage {
  display: flex;
  flex-direction: column;
  padding: 0 13px;
  align-items: center;
}

.modalMessage p {
  text-align: left;
}

.modalMessage h2 {
  margin-bottom: 50px;
}

.input_wrapper {
  margin-bottom: 16px;
}

.input {
  width: 331px;
  text-align: left;
}

.error {
  margin-bottom: 50px;
  text-align: left;
}

.modal :global .ant-modal-close {
  width: 24px;
  height: 16px;
  margin-top: 11px;
  margin-right: 17px;
}

.modal :global .ant-modal-close-x {
  width: 24px;
  height: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--gold-500);
  font-size: 12px;
}
.button_close {
  width: 24px;
  height: 16px;
}
a.supportLink {
  text-decoration: underline;
  color: var(--velvet-400);
}
a.supportLink:hover {
  color: var(--velvet-300);
}
