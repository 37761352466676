.wrapperTable {
  flex-grow: 1;
}

.wrapperTable td,
.wrapperTable th {
  height: 48px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 12px;
}

.title {
  display: flex;
  margin-bottom: 0 !important;
}

.circle {
  display: flex;
  color: var(--gold-700);
  background-color: var(--gold-300);
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 14px;
  margin-left: 11px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 600;
}

.nameCol {
  display: flex;
  flex-direction: column;
  font-weight: 700;
}

.upgrade,
.upgrade button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  z-index: 2;
}

.ant-pagination-total-text {
  position: absolute;
  right: 0;
  top: 10px;
}

.ant-table-pagination.ant-pagination {
  margin-top: 96px;
}

.settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hiddenButtons,
.iconDisabled {
  opacity: 0;
  transition-duration: 0.3s;
  z-index: 1000;
}

.tableRow:hover .hiddenButtons {
  opacity: 1;
}

.tableRow path,
.tableRow:hover .available:hover path,
.tableRow:hover .available path {
  fill: var(--dark-700);
}

.tableRow:hover .iconDisabled {
  opacity: 1;
  cursor: not-allowed;
  fill: var(--light-700);
  color: var(--light-700);
  pointer-events: all;
}

.tableRow:hover {
  cursor: pointer;
}

.tableRow .disabledSet {
  cursor: not-allowed;
}

.tableRow .disabledSet path,
.tableRow:hover .disabledSet path,
.disabled:hover .disabledSet path {
  fill: var(--light-700);
  color: var(--light-700);
}

.table :global(.ant-table-empty .ant-table-tbody) {
  display: none;
}

.noData {
  margin-top: 150px;
  text-align: center;
}

.noDataTitle {
  font-weight: 600;
}

.upgrade path {
  fill: var(--gold-500);
}

.tooltipCameraList-config {
  min-width: 48px;
}

.velvet {
  color: var(--velvet-500);
}

.soft {
  color: var(--soft-300);
}

.gold {
  color: var(--gold-500);
}

.tableRow td :global(.ant-checkbox-checked .ant-checkbox-inner:after) {
  transform: rotate(45deg) scale(0.6) translate(-70%, -80%);
}

.tableRow td :global(.ant-checkbox-input) {
  border-color: var(--soft-700);
}

.tableRow td :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--soft-700);
}

.tableFooterAction :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--soft-700);
}

.tableFooterAction {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px;
  border: none;
  border-radius: 4px;
  background-color: rgba(115, 127, 135, 0.1);
  color: var(--soft-700);
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tableFooterAction:hover {
  background-color: rgba(115, 127, 135, 0.3);
}

.tableFooterAction.disabled {
  background-color: var(--grey-light-700);
}

.tableFooterAction button:disabled {
  border: none;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  gap: 6px;
  display: flex;
  color: var(--soft-300);
  background-color: var(--grey-light-700);
  padding: 0;
}

.tableFooterAction.disabled:hover {
  border: none;
  color: var(--soft-300);
  background-color: var(--grey-light-700);
}

.tableSelectAll {
  composes: tableFooterAction;
  margin-right: 35px;
}

.tableSelectAll :global(.ant-checkbox-inner) {
  width: 14px;
  height: 14px;
  font-size: 14px;
}

.tableSelectAll :global(.ant-checkbox-inner:after) {
  transform: rotate(45deg) scale(0.4) translate(-180%, -90%);
}

.tableSelectAll :global(.ant-checkbox-indeterminate .ant-checkbox-inner:after) {
  transform: rotate(0) scale(0.4) translate(-130%, -100%);
}

.tableSelectAll span:not(:global(.antd-checkbox)) {
  font-size: 10px;
  font-weight: 600;
  padding: 0;
}
