.confirmInstallText {
  width: 358px;
}

.confirmInstallText p{
  white-space: pre-line;
}

.confirmContainer {
  filter: invert(1);
}

.progressStyles,
.progressStyles:hover,
.progressStyles:active,
.progressStyles:focus {
  background-color: transparent;
  color: rgb(34, 203, 194);
  border: 1px solid rgb(34, 203, 194);
  outline: unset;
}

.confirmButton {
  composes: progressStyles;
  overflow: hidden;
  user-select: none;
  outline: unset;
  width: max-content;
}

.confirmButton::after {
  all: unset;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 0;
  content: '';
}

.confirmButton:active::after {
  animation: loading 3s cubic-bezier(0.04, 0.6, 0.22, 0.71) normal;
  background: currentColor;
  mix-blend-mode: difference;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
